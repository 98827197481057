// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/usr/projects/zktWeb/20210831-172847/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-downloadapp-js": () => import("/usr/projects/zktWeb/20210831-172847/src/pages/downloadapp.js" /* webpackChunkName: "component---src-pages-downloadapp-js" */),
  "component---src-pages-downloadp-js": () => import("/usr/projects/zktWeb/20210831-172847/src/pages/downloadp.js" /* webpackChunkName: "component---src-pages-downloadp-js" */),
  "component---src-pages-downloady-js": () => import("/usr/projects/zktWeb/20210831-172847/src/pages/downloady.js" /* webpackChunkName: "component---src-pages-downloady-js" */),
  "component---src-pages-homep-js": () => import("/usr/projects/zktWeb/20210831-172847/src/pages/homep.js" /* webpackChunkName: "component---src-pages-homep-js" */),
  "component---src-pages-homey-js": () => import("/usr/projects/zktWeb/20210831-172847/src/pages/homey.js" /* webpackChunkName: "component---src-pages-homey-js" */),
  "component---src-pages-index-js": () => import("/usr/projects/zktWeb/20210831-172847/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-masterp-js": () => import("/usr/projects/zktWeb/20210831-172847/src/pages/masterp.js" /* webpackChunkName: "component---src-pages-masterp-js" */),
  "component---src-pages-mastery-js": () => import("/usr/projects/zktWeb/20210831-172847/src/pages/mastery.js" /* webpackChunkName: "component---src-pages-mastery-js" */),
  "component---src-pages-newsp-js": () => import("/usr/projects/zktWeb/20210831-172847/src/pages/newsp.js" /* webpackChunkName: "component---src-pages-newsp-js" */),
  "component---src-pages-newsy-js": () => import("/usr/projects/zktWeb/20210831-172847/src/pages/newsy.js" /* webpackChunkName: "component---src-pages-newsy-js" */),
  "component---src-pages-page-1-js": () => import("/usr/projects/zktWeb/20210831-172847/src/pages/page-1.js" /* webpackChunkName: "component---src-pages-page-1-js" */),
  "component---src-pages-teacherp-js": () => import("/usr/projects/zktWeb/20210831-172847/src/pages/teacherp.js" /* webpackChunkName: "component---src-pages-teacherp-js" */),
  "component---src-pages-teachery-js": () => import("/usr/projects/zktWeb/20210831-172847/src/pages/teachery.js" /* webpackChunkName: "component---src-pages-teachery-js" */)
}

